var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.history.length
    ? _c(
        "section",
        [
          _c("div", { staticClass: "d-flex mb-4 mt-4" }, [
            _c("h4", { staticClass: "bold text-muted text-dark mb-0" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("round_to_top_history")) +
                  "\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _c("mega-card", { staticClass: "mt-4" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              _vm._l(_vm.history, function(item, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass:
                      "w-100 navbar-label align-items-center text-dark",
                    class: { "mt-3": i > 0 }
                  },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c("small", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("round_to_top_date")) + ":")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "strong" }, [
                          _vm._v(_vm._s(item.a_time))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v("User: " + _vm._s(item.admin.email))
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }